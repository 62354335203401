form{
    background-color: white;
    padding: 0px ;
    border-radius: 10px;
    width: 100%;
  }
  h1{
    color: rgb(77, 1, 77);
    text-align: center;
  }
  .center-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .Forgotpassword{
    width: 25%;
  padding-right: 50px;
  padding-left: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-right: auto;
  margin-left: auto;
  background-color: white;
  /* border-radius: 50px; */
  } 
  button{
    width: 100%;
    height: 50px;
    padding: 10px;
    border: none;
    background-color: #605BFF;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 30px;
  }